import { FormEvent } from 'react';

import { ApiError, EmailOrUsernameExists, SecureService, SupertokensAuthMode } from '@common/clients/api';
import { ApiService } from '@common/clients/request/ApiService';

import {
    LinkAndSignInParams,
    PasswordResetParams,
    RequestStatus,
    SendPasswordResetEmailParams,
    SignInOrSignUpParams,
    SignInParams,
} from './types';
import { checkEmailOrUsernameExists, FormType, validateEmail, validateUsername } from './utils';

export const onPasswordReset = async (
    e: FormEvent,
    { contextData, password, setFormError, callback, __secure }: PasswordResetParams,
) => {
    e.preventDefault();
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (!token) {
        setFormError(__secure('email-verification.missing-token'));
        return;
    }

    try {
        if (password)
            await ApiService({
                contextData,
                isClientSide: true,
                service: SecureService,
            }).resetPasswordSecure({
                requestBody: { token, password },
            });
        callback();
    } catch (error) {
        if (error instanceof ApiError && hasMessage(error.body) && error.body.message === 'INVALID_TOKEN') {
            setFormError(__secure('signin.invalidToken'));
        } else {
            setFormError(__secure('signin.formError'));
        }
    }
};

type WithMessage = {
    message: string;
};

function hasMessage(u: unknown): u is WithMessage {
    return (u as WithMessage)?.message !== undefined;
}

export const onSendPasswordResetEmail = async (
    e: FormEvent,
    {
        contextData,
        emailOrUsername,
        setFormError,
        setFormState,
        setRequestStatus,
        __secure,
    }: SendPasswordResetEmailParams,
): Promise<void> => {
    e.preventDefault();
    setRequestStatus(RequestStatus.LOADING);

    if (!emailOrUsername) {
        setFormError(__secure('signin.errorValidEmail'));
        setRequestStatus(RequestStatus.ERROR);
        return;
    }

    try {
        await ApiService({
            contextData,
            isClientSide: true,
            service: SecureService,
        }).sendPasswordResetEmailSecure({
            editionId: contextData.context.id,
            requestBody: { email: emailOrUsername },
        });
        setFormState(FormType.FORGOT_PASSWORD_SEND_EMAIL);
        setFormError('');
        setRequestStatus(RequestStatus.SUCCESS);
    } catch (error) {
        if (error instanceof ApiError && hasMessage(error.body)) {
            if (error.body.message === 'PASSWORD_MUST_BE_SET_BY_ADMIN') {
                setFormError(__secure('signin.askAdminToResetPassword'));
            } else if (error.body.message === 'USER_NOT_FOUND') {
                setFormError(__secure('signin.emailNotUsed'));
            } else {
                setFormError(__secure('signin.formError'));
            }
        } else {
            setFormError(__secure('signin.formError'));
        }
        setRequestStatus(RequestStatus.ERROR);
    }
};

export const handleSignInOrSignUp = async (
    e: FormEvent,
    {
        emailOrUsername,
        setIsLoading,
        setEmail,
        setUsername,
        setFormState,
        setLinkedPlatforms,
        setFormError,
        setPropertyErrors,
        propertyErrors,
        __secure,
        contextData,
    }: SignInOrSignUpParams,
) => {
    e.preventDefault();
    setIsLoading(true);

    try {
        const result: EmailOrUsernameExists = await checkEmailOrUsernameExists(emailOrUsername, contextData);
        if (result.isEmail) {
            setEmail(emailOrUsername);

            const { isValid: isValidEmail, errors: emailErrors } = await validateEmail({
                email: emailOrUsername,
                contextData,
                checkIfExists: false,
                __secure,
            });

            if (!isValidEmail) {
                setEmail('');
                setPropertyErrors({ ...propertyErrors, email: emailErrors });
                setIsLoading(false);
                return;
            }
            if (result.exists) {
                setPropertyErrors({});
                if (!result.platforms.includes(contextData.platform.id)) {
                    setFormState(FormType.OPT_IN);
                    setLinkedPlatforms(result.platforms);
                } else {
                    setFormState(FormType.SIGN_IN);
                }
            } else {
                setPropertyErrors({});
                setFormState(FormType.SIGN_UP);
            }
        } else {
            setUsername(emailOrUsername);

            if (result.exists) {
                setPropertyErrors({});
                if (!result.platforms.includes(contextData.platform.id)) {
                    setFormState(FormType.OPT_IN);
                    setLinkedPlatforms(result.platforms);
                } else {
                    setFormState(FormType.SIGN_IN);
                }
            } else {
                const { isValid: isValidUsername, errors: usernameErrors } = await validateUsername({
                    username: emailOrUsername,
                    contextData,
                    checkIfExists: false,
                    __secure,
                });
                if (!isValidUsername) {
                    setUsername('');
                    setPropertyErrors({ ...propertyErrors, username: usernameErrors });
                    setIsLoading(false);
                    return;
                }
                setPropertyErrors({});
                setFormState(FormType.SIGN_UP);
            }
        }
    } catch (error) {
        if (error instanceof Error) {
            setFormError(__secure('signin.formError'));
        }
    } finally {
        setIsLoading(false);
    }
};

export const handleSignIn = async (
    e: FormEvent,
    {
        emailOrUsername,
        password,
        setIsLoading,
        setFormError,
        afterSignIn,
        __secure,
        contextData,
    }: SignInParams,
) => {
    e.preventDefault();
    setIsLoading(true);

    try {
        await ApiService({
            contextData,
            isClientSide: true,
            service: SecureService,
        }).signInPasswordSecure({
            stAuthMode: SupertokensAuthMode.COOKIE,
            requestBody: { emailOrUsername, password },
        });
        afterSignIn();
    } catch (error) {
        if (error instanceof Error) {
            setFormError(__secure('error.password'));
        }
    } finally {
        setIsLoading(false);
    }
};

export const handleLinkAccountAndSignIn = async (
    e: FormEvent,
    {
        emailOrUsername,
        password,
        linkedPlatforms,
        setIsLoading,
        setFormError,
        afterSignIn,
        __secure,
        contextData,
    }: LinkAndSignInParams,
) => {
    e.preventDefault();
    setIsLoading(true);

    try {
        await ApiService({
            contextData,
            isClientSide: true,
            service: SecureService,
        }).linkAndSignInPasswordSecure({
            stAuthMode: SupertokensAuthMode.COOKIE,
            requestBody: {
                emailOrUsername,
                password,
                linkedPlatforms,
            },
        });
        afterSignIn();
    } catch (error) {
        if (error instanceof Error) {
            setFormError(__secure('error.password'));
        }
    } finally {
        setIsLoading(false);
    }
};
