import { Translate } from '@cms/i18n';
import { ApiError, EmailOrUsernameExists, UserService } from '@common/clients/api';
import { ApiService } from '@common/clients/request/ApiService';
import { ContextData } from '@common/defaults';
import { checkPassword, PasswordError } from '@common/supertokens/checkPassword';
import { MAXIMUM_PASSWORD_LENGTH, MINIMUM_PASSWORD_LENGTH } from '@common/supertokens/constants';
import { isEmailAddress } from '@common/utils/EmailUtil';

export enum FormType {
    FORGOT_PASSWORD = 'forgot-password',
    FORGOT_PASSWORD_SEND_EMAIL = 'forgot-password-send-email',
    FORGOT_PASSWORD_SET_PASSWORD = 'forgot-password-set-password',
    FORGOT_PASSWORD_SUCCESS = 'forgot-password-success',
    LINK_AND_SIGN_IN = 'link-and-sign-in',
    OPT_IN = 'opt-in',
    SIGN_IN = 'sign-in',
    SIGN_IN_OR_SIGN_UP = 'sign-in-or-sign-up',
    SIGN_UP = 'sign-up',
    SIGN_UP_SUCCESS = 'sign-up-success',
}

export interface FormCallbacks {
    onClose: () => void;
    onGoBack: () => void;
    onSuccess: () => void;
}

export type ValidationResponse = { isValid: boolean; errors: string[] };

export const checkEmailExists = async (email: string, contextData: ContextData): Promise<boolean> => {
    return ApiService({
        contextData,
        isClientSide: true,
        service: UserService,
    })
        .emailExistsUser({ email })
        .catch((error) => {
            if (
                error instanceof ApiError &&
                (error.body as { name: string }).name === 'AJV_VALIDATION_ERROR'
            ) {
                return false;
            }
            throw error;
        });
};

export const checkUsernameExists = async (username: string, contextData: ContextData): Promise<boolean> => {
    return ApiService({
        contextData,
        isClientSide: true,
        service: UserService,
    })
        .usernameExistsUser({ username })
        .catch((error) => {
            if (
                error instanceof ApiError &&
                (error.body as { name: string }).name === 'AJV_VALIDATION_ERROR'
            ) {
                return false;
            }
            throw error;
        });
};

export const checkEmailOrUsernameExists = async (
    emailOrUsername: string,
    contextData: ContextData,
): Promise<EmailOrUsernameExists> => {
    return ApiService({
        contextData,
        isClientSide: true,
        service: UserService,
    }).emailOrUsernameExistsUser({ emailOrUsername });
};

export const validateAcceptTerms = (acceptTerms: boolean, __secure: Translate): ValidationResponse => {
    const errors: string[] = [];
    if (!acceptTerms) {
        errors.push(__secure('signin.errorTerms'));
    }
    return { isValid: errors.length === 0, errors };
};

export const validateBirthDate = (birthDate: string, __secure: Translate): ValidationResponse => {
    const errors: string[] = [];
    const birthDateRegex = /^\d{4}([./-])\d{2}\1\d{2}$/;

    if (!birthDate) {
        errors.push(__secure('signin.errorBirthDateRequired'));
    } else {
        if (!birthDateRegex.test(birthDate)) {
            errors.push(__secure('signin.errorValidBirthDate'));
        } else {
            const parsedDate = new Date(birthDate);
            const today = new Date();

            const minAllowedAge = new Date(today.getFullYear() - 16, today.getMonth(), today.getDate());

            if (parsedDate > today) {
                errors.push(__secure('signin.errorBirthdateFuture'));
            } else if (parsedDate > minAllowedAge) {
                errors.push(__secure('signin.errorAgeTooYoung'));
            }
        }
    }

    return { isValid: errors.length === 0, errors };
};

export const validateEmail = async ({
    email,
    contextData,
    __secure,
    checkIfExists = false,
}: {
    email: string;
    contextData: ContextData;
    __secure: Translate;
    checkIfExists?: boolean;
}): Promise<ValidationResponse> => {
    const errors: string[] = [];
    const isEmail = isEmailAddress(email);
    if (!isEmail) {
        errors.push(__secure('signin.errorValidEmail'));
    }
    if (checkIfExists && email) {
        const emailExists = await checkEmailExists(email, contextData);
        if (emailExists) {
            errors.push(__secure('signin.errorEmail'));
        }
    }
    return { isValid: errors.length === 0, errors };
};

export const validateEmailOrUsername = async (
    value: string,
    contextData: ContextData,
    __secure: Translate,
    checkIfExists = false,
): Promise<boolean> => {
    const isEmail = value.includes('@');
    if (isEmail) {
        return (await validateEmail({ email: value, contextData, __secure, checkIfExists })).isValid;
    } else {
        return (
            await validateUsername({
                username: value,
                contextData,
                checkIfExists,
                __secure,
            })
        ).isValid;
    }
};

export const validatePassword = (password: string, __secure: Translate): ValidationResponse => {
    const { errors: passwordErrors, isValid } = checkPassword(password);

    const errors = passwordErrors.map((error) => {
        switch (error) {
            case PasswordError.TOO_SHORT:
                return __secure('signin.pwTooShort', { minLength: MINIMUM_PASSWORD_LENGTH });
            case PasswordError.TOO_LONG:
                return __secure('signin.pwTooLong', { maxLength: MAXIMUM_PASSWORD_LENGTH });
            case PasswordError.REPEATED_SEQUENCE:
                return __secure('signin.pwRepeatedSequence');
            default:
                return '';
        }
    });

    return { isValid, errors };
};

export const validateFullName = (fullName: string, __secure: Translate): ValidationResponse => {
    const errors: string[] = [];
    if (fullName.length < 1) {
        errors.push(__secure('signin.errorFullname'));
    }
    return { isValid: errors.length === 0, errors };
};

export const validateUsername = async ({
    username,
    contextData,
    checkIfExists = false,
    __secure,
}: {
    username: string;
    contextData: ContextData;
    checkIfExists?: boolean;
    __secure: Translate;
}): Promise<ValidationResponse> => {
    const errors: string[] = [];
    const usernameRegex = /^[a-zA-Z0-9_-]{3,16}$/;
    if (!usernameRegex.test(username)) {
        errors.push(__secure('signin.errorUsernameCharacters'));
    }
    if (checkIfExists) {
        const usernameExists = await checkUsernameExists(username, contextData);
        if (usernameExists) {
            errors.push(__secure('signin.errorUsernameTaken'));
        }
    }
    return { isValid: errors.length === 0, errors };
};
