import { FC } from 'react';
import Script from 'next/script';

interface Props {
    captchaSiteKey: string;
}

export const CaptchaScript: FC<Props> = ({ captchaSiteKey }) => {
    const captchaUrl = new URL('https://www.google.com/recaptcha/api.js');
    captchaUrl.searchParams.append('render', captchaSiteKey);

    return <Script src={captchaUrl.toString()} />;
};
