import {
    ChangeEvent,
    FC,
    FormEvent,
    useCallback,
    useDeferredValue,
    useEffect,
    useState,
    useTransition,
} from 'react';

import { useTranslation } from '@cms/i18n';
import { useContextData } from '@common/useContextData';
import { InputElement } from '@web/_common/InputElement';
import { Type } from '@web/_common/InputElement/InputElement';
import { About } from '@web/templates/Signin/atoms/About/About';
import { Footer } from '@web/templates/Signin/atoms/Footer/Footer';
import { LogoContainer } from '@web/templates/Signin/atoms/LogoContainer/LogoContainer';
import { Navigation } from '@web/templates/Signin/atoms/Navigation/Navigation';
import { SwitchToSignUp } from '@web/templates/Signin/atoms/SwitchToSignUp/SwitchToSignUp';
import { ValidationErrors } from '@web/templates/Signin/atoms/ValidationErrors/ValidationErrors';
import { useAuthValidationHook } from '@web/templates/Signin/useAuthValidation';
import { FormCallbacks } from '@web/templates/Signin/utils';

import { FlowType } from '../../Auth';

import styles from './SignInOrSignUp.module.scss';

interface Props extends Omit<FormCallbacks, 'onSuccess' | 'onGoBack'> {
    emailOrUsername: string;
    isLoading: boolean;
    formError: string;
    propertyErrors: { [key: string]: string[] };
    onEmailOrUsernameChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onSignInOrSignUp: (e: FormEvent) => void;
    onSwitchToSignUpForm: () => void;
    setPropertyErrors: (errors: { [key: string]: string[] }) => void;
    flowType?: FlowType;
    password?: string;
    onPasswordChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    onSignIn: (e: FormEvent) => void;
}

export const SignInOrSignUp: FC<Props> = ({
    emailOrUsername,
    isLoading,
    formError,
    propertyErrors,
    onEmailOrUsernameChange,
    onSignInOrSignUp,
    onSwitchToSignUpForm,
    onClose,
    flowType = FlowType.STANDALONE,
    password = '',
    onPasswordChange,
    onSignIn,
}) => {
    const __secure = useTranslation('secure').t;
    const contextData = useContextData();

    const [animationClass] = useState(styles['first-load-animation']);
    const [isLoadMore, setActive] = useState<boolean>(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
    const [isPending, startTransition] = useTransition();

    const deferredEmailOrUsername = useDeferredValue(emailOrUsername);
    const deferredPassword = useDeferredValue(password);

    useEffect(() => {
        setIsSubmitDisabled(!emailOrUsername);
    }, [emailOrUsername]);

    const [authValidationState, updateAuthState] = useAuthValidationHook();

    const handleFormSubmit = useCallback(
        (e: FormEvent, formEmailOrUsername: string, formPassword?: string) => {
            e.preventDefault();

            updateAuthState(formEmailOrUsername, 'email').then(() => {
                if (!!formPassword?.length && authValidationState.password.errors.length === 0) {
                    startTransition(() => {
                        onSignIn(e);
                    });
                } else {
                    startTransition(() => {
                        onSignInOrSignUp(e);
                    });
                }
            });
        },
        [onSignIn, onSignInOrSignUp, updateAuthState, authValidationState],
    );

    const handleLoadMore = () => {
        setActive(!isLoadMore);
    };

    return (
        <div
            className={`${styles.SignInOrSignUp} ${flowType === FlowType.SUBSCRIPTION ? styles['subscription-flow'] : ''}`}
        >
            <div className={`${styles.wrapper} ${animationClass}`}>
                {flowType === FlowType.STANDALONE ? (
                    <Navigation showBackButton={true} onClose={onClose} />
                ) : null}
                <div className={styles.container}>
                    <LogoContainer contextData={contextData} />
                    <h1> {__secure('signin.title')}</h1>
                    <p className={styles.intro}>{__secure('signin.intro')}</p>
                    {formError ? (
                        <div className={styles['form-error']}>
                            <p className={styles.error}>{formError}</p>
                        </div>
                    ) : null}
                    <form onSubmit={(e) => handleFormSubmit(e, deferredEmailOrUsername, deferredPassword)}>
                        <div
                            className={`${styles['input-field']} ${
                                authValidationState.username?.errors.length > 0 || propertyErrors.email
                                    ? styles.errorField
                                    : ''
                            }`}
                        >
                            <InputElement
                                actions={[]}
                                id="emailOrUsername"
                                title={__secure('signin.emailOrUsername')}
                                type={Type.text}
                                value={deferredEmailOrUsername}
                                isMandatory
                                onChange={onEmailOrUsernameChange}
                                autoCapatilize={'none'}
                                autoFocus={!emailOrUsername}
                            />
                            {propertyErrors.username ? (
                                <ValidationErrors errors={propertyErrors.username} />
                            ) : null}
                            {propertyErrors.email ? <ValidationErrors errors={propertyErrors.email} /> : null}
                            <div
                                className={
                                    password?.length > 0
                                        ? styles['show-password-field']
                                        : styles['hide-password-field']
                                }
                            >
                                <InputElement
                                    actions={[]}
                                    id="password"
                                    title={__secure('signin.password')}
                                    type={Type.password}
                                    value={deferredPassword}
                                    onChange={onPasswordChange}
                                />
                            </div>
                            {propertyErrors.password ? (
                                <ValidationErrors errors={propertyErrors.password} />
                            ) : null}
                        </div>
                        <div className={styles['submit-button']}>
                            <input
                                className={`button filled`}
                                value={isLoading ? 'loading...' : __secure`continue`}
                                type="submit"
                                disabled={isSubmitDisabled || isPending}
                            />
                        </div>
                    </form>
                    <SwitchToSignUp onSwitch={onSwitchToSignUpForm} />
                    {flowType === FlowType.STANDALONE ? (
                        <About isLoadMore={isLoadMore} handleLoadMore={handleLoadMore} />
                    ) : null}
                </div>
            </div>
            {flowType === FlowType.STANDALONE ? <Footer /> : null}
        </div>
    );
};
