import { FC, FormEvent, useState } from 'react';

import { useTranslation } from '@cms/i18n';
import { Icon } from '@common/atoms/Icon';
import { useContextData } from '@common/useContextData';
import { InternalLink, Route } from '@web/atoms/InternalLink';
import { Footer } from '@web/templates/Signin/atoms/Footer/Footer';
import { LogoContainer } from '@web/templates/Signin/atoms/LogoContainer/LogoContainer';
import { Navigation } from '@web/templates/Signin/atoms/Navigation/Navigation';
import { FormCallbacks, FormType } from '@web/templates/Signin/utils';

import { onSendPasswordResetEmail } from '../../AuthHandlers';
import { RequestStatus } from '../../types';

import styles from './ForgotPassword.module.scss';

interface Props extends Pick<FormCallbacks, 'onClose'> {
    email: string;
    setFormState: (newFormState: FormType) => void;
}

export const ForgotPasswordSendEmail: FC<Props> = ({ onClose, email, setFormState }) => {
    const __secure = useTranslation('secure').t;
    const contextData = useContextData();

    const [animationClass] = useState(styles['form-animation']);

    const [formError, setFormError] = useState<string>('');
    const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.NOT_SENT);
    const handleSendPasswordResetEmail = (e: FormEvent) => {
        if (requestStatus === RequestStatus.LOADING || requestStatus === RequestStatus.SUCCESS) {
            return;
        }
        onSendPasswordResetEmail(e, {
            contextData,
            emailOrUsername: email,
            setFormError,
            setFormState,
            setRequestStatus,
            __secure,
        });
    };

    return (
        <div className={styles.ForgotPassword}>
            <div className={styles.wrapper}>
                <Navigation showBackButton={true} onClose={onClose} />
                <div className={styles.container}>
                    <LogoContainer contextData={contextData} />
                    <div className={`${styles.content} ${animationClass}`}>
                        <Icon.check />
                        <h1> {__secure('signin.passwordSendEmail')}</h1>
                        <p className={styles.intro}>{__secure('signin.passwordSendEmailIntro')}</p>
                    </div>
                </div>
                <div className={styles['resend-email']}>
                    <p>{__secure('signin.noEmail')}</p>
                    <p>{__secure('signin.tipEmail')}</p>
                    <button className="outlined" onClick={handleSendPasswordResetEmail}>
                        {__secure('signin.resendEmail')}
                    </button>
                </div>
                {requestStatus === RequestStatus.SUCCESS ? (
                    <div className={styles['form-success']}>
                        <p>{__secure('signin.emailResent')}</p>
                    </div>
                ) : null}
                {requestStatus === RequestStatus.ERROR ? (
                    <div className={styles['form-error']}>
                        <p>{formError}</p>
                    </div>
                ) : null}
                <div className={styles['resend-email']}>
                    <p>{__secure('signin.needHelpEmail')}</p>
                    <InternalLink route={Route.Page} query={{ slug: __secure('url:contact') }}>
                        <button className="outlined">{__secure('signin.contact')}</button>
                    </InternalLink>
                </div>
            </div>
            <Footer />
        </div>
    );
};
