import { FC } from 'react';

import { useTranslation } from '@cms/i18n';
import { Icon } from '@common/atoms/Icon';

import styles from './About.module.scss';

export const About: FC<{ isLoadMore: boolean; handleLoadMore: () => void }> = ({
    isLoadMore,
    handleLoadMore,
}) => {
    const __secure = useTranslation('secure').t;
    return (
        <div className={styles.About}>
            <div className={styles.content}>
                <a className={isLoadMore ? styles['is-shown'] : ''} onClick={handleLoadMore}>
                    {__secure('signin.about')} {isLoadMore ? <Icon.upCenter /> : <Icon.downCenter />}
                </a>
            </div>
            <div className={isLoadMore ? styles['load-more'] : styles.default}>
                <p>{__secure`aboutPxr`}</p>
            </div>
        </div>
    );
};
