import { FC } from 'react';

import styles from './ValidationErrors.module.scss';

export const ValidationErrors: FC<{ errors: string[]; animationClass?: string; hasPadding?: boolean }> = ({
    errors,
    animationClass,
    hasPadding,
}) => {
    if (!errors || errors.length === 0) {
        return null;
    }

    return (
        <div
            className={`${styles.ValidationError} ${animationClass} ${hasPadding ? styles.extraPadding : ''}`}
        >
            {errors.map((error, index) => (
                <p key={index}>{error}</p>
            ))}
        </div>
    );
};
