import { ChangeEvent, FC, FormEvent, useCallback, useEffect, useState } from 'react';
import router from 'next/router';
import { GetServerSidePropsContext } from 'next/types';
import absoluteUrl from 'next-absolute-url';

import { useTranslation } from '@cms/i18n';
import { PlatformID } from '@common/clients/api';
import { ContextData } from '@common/defaults';
import { useContextData } from '@common/useContextData';
import { useUserContext } from '@common/useUserContext/UserContextProvider';
import useUserIsLoading from '@common/useUserContext/useUserIsLoading';
import { formatInternalRoute, Route } from '@web/routing';
import { SignUpSuccess } from '@web/templates/Signin/organisms/SignUp/SignUpSuccess';

import { ForgotPasswordSendEmail } from './organisms/ForgotPassword/ForgotPasswordSendEmail';
import { OptIn } from './organisms/OptIn/OptIn';
import { handleLinkAccountAndSignIn, handleSignIn, handleSignInOrSignUp } from './AuthHandlers';
import { ForgotPassword, SignIn, SignInOrSignUp, SignUp } from './organisms';
import { FormType } from './utils';

export enum FlowType {
    STANDALONE = 'standalone',
    SUBSCRIPTION = 'subscription',
}

export interface Props {
    serverRefererUrl?: string;
    flowType: FlowType;
    onSignUpSuccess?: () => void;
}

/**
 * Get the referer URL from the 'referer' query parameter or the 'referer' header.
 * It returns undefined if the referer URL doesn't match the domain pattern or if the hostname and pathname are the same as the current hostname and pathname.
 */
export function getServerRefererUrl(
    contextData: ContextData,
    serverContext: GetServerSidePropsContext,
): string | undefined {
    let serverRefererUrl: string | undefined = Array.isArray(serverContext.query?.referer)
        ? serverContext.query.referer[0]
        : serverContext.query?.referer;
    if (!serverRefererUrl) {
        serverRefererUrl = serverContext.req.headers.referer;
    }
    if (!serverRefererUrl) {
        return undefined;
    }

    const { origin } = absoluteUrl(serverContext.req);

    let refererURL: URL;
    try {
        refererURL = new URL(serverRefererUrl, origin);
    } catch (e) {
        return undefined;
    }

    const domainPattern = contextData.context.pattern;
    if (!domainPattern || !refererURL.hostname.match(new RegExp(domainPattern))) {
        return undefined;
    }

    let serverContextURL: URL;
    if (!serverContext.req.url) {
        return undefined;
    }
    try {
        serverContextURL = new URL(serverContext.req.url, origin);
    } catch (e) {
        return undefined;
    }
    if (
        serverContextURL.hostname === refererURL.hostname &&
        serverContextURL.pathname === refererURL.pathname
    ) {
        return undefined;
    }

    return serverRefererUrl;
}

export const Auth: FC<Props> = ({ serverRefererUrl: refererUrl, flowType, onSignUpSuccess }) => {
    const contextData = useContextData();
    const { state } = useUserContext();
    const { isLoggedIn, isUnverified } = state;
    const userIsLoading = useUserIsLoading();
    const __secure = useTranslation('secure').t;
    const __url = useTranslation('url').t;

    // Form state
    const [formState, setFormState] = useState(
        isUnverified ? FormType.SIGN_UP_SUCCESS : FormType.SIGN_IN_OR_SIGN_UP,
    );

    const updateFormState = useCallback((newFormState: FormType) => {
        setFormState((currentFormState) =>
            currentFormState !== newFormState ? newFormState : currentFormState,
        );
    }, []);

    onSignUpSuccess = onSignUpSuccess || (() => updateFormState(FormType.SIGN_UP_SUCCESS));

    useEffect(() => {
        if (isUnverified && flowType === FlowType.STANDALONE) {
            updateFormState(FormType.SIGN_UP_SUCCESS);
        }
    }, [isUnverified, updateFormState, flowType]);

    const [emailOrUsername, setEmailOrUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [username, setUsername] = useState('');
    const [fullName, setFullName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [linkedPlatforms, setLinkedPlatforms] = useState<PlatformID[]>([]);
    const [formError, setFormError] = useState('');
    const [propertyErrors, setPropertyErrors] = useState<{ [key: string]: string[] }>({});

    // Form handlers
    const handleEmailOrUsernameChange = (e: ChangeEvent<HTMLInputElement>) =>
        setEmailOrUsername(e.target.value);
    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);
    const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);
    const handleToggleShowPassword = () => setShowPassword(!showPassword);
    const handleUsernameChange = (e: ChangeEvent<HTMLInputElement>) => setUsername(e.target.value);
    const handleFullNameChange = (e: ChangeEvent<HTMLInputElement>) => setFullName(e.target.value);
    const handleSwitchToSignUpForm = () => updateFormState(FormType.SIGN_UP);
    const handleSwitchToSignInForm = () => updateFormState(FormType.SIGN_IN);
    const handleSwitchToForgotPasswordForm = () => updateFormState(FormType.FORGOT_PASSWORD);

    /**
     * Use this function when the user cancels the signin or signup. It will redirect the user to the homepage.
     */
    const redirectToHomepage = () => {
        const formattedRoute = formatInternalRoute(Route.Homepage, contextData.context.slug, __url);
        router.push(formattedRoute);
    };

    /**
     * Use this function to redirect the user after a successful signin. It will redirect the user to the `refererUrl`, if provided. Otherwise, it redirects the user to the homepage.
     *
     * If the `flowType` isn't `FlowType.STANDALONE`, then this function does nothing.
     */
    const doRedirect = () => {
        if (flowType !== FlowType.STANDALONE) {
            return;
        }
        const homepageUrl = formatInternalRoute(Route.Homepage, contextData.context.slug, __url);
        window.location.href = refererUrl ?? homepageUrl;
    };

    useEffect(() => {
        if (isLoggedIn && !userIsLoading && flowType === FlowType.STANDALONE) {
            doRedirect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, userIsLoading, flowType]);

    const resetFormFieldsAndSetState = (newFormState: FormType) => {
        setEmail('');
        setUsername('');
        setFullName('');
        setPassword('');
        setPropertyErrors({});
        setFormError('');
        updateFormState(newFormState);
    };
    const handleGoBackToSignInOrSignUp = () => resetFormFieldsAndSetState(FormType.SIGN_IN_OR_SIGN_UP);
    const handleGoBackToSignIn = () => resetFormFieldsAndSetState(FormType.SIGN_IN);
    const handleShowSignUpSuccess = onSignUpSuccess;
    const handleSwitchToSignUpFormOptIn = () => resetFormFieldsAndSetState(FormType.SIGN_UP);
    const handleGoToLinkAndSignIn = () => {
        updateFormState(FormType.LINK_AND_SIGN_IN);
    };

    switch (formState) {
        case FormType.FORGOT_PASSWORD:
            return (
                <ForgotPassword
                    email={emailOrUsername}
                    formError={formError}
                    setFormError={setFormError}
                    setFormState={updateFormState}
                    onEmailChange={handleEmailOrUsernameChange}
                    onSwitchToSignInForm={handleSwitchToSignInForm}
                    onClose={redirectToHomepage}
                    onGoBack={handleGoBackToSignIn}
                />
            );
        case FormType.FORGOT_PASSWORD_SEND_EMAIL:
            return (
                <ForgotPasswordSendEmail
                    setFormState={updateFormState}
                    onClose={redirectToHomepage}
                    email={emailOrUsername}
                />
            );
        case FormType.OPT_IN:
            return (
                <OptIn
                    email={emailOrUsername}
                    onSwitchToForgotPasswordForm={handleSwitchToForgotPasswordForm}
                    onSwitchToSignUpForm={handleSwitchToSignUpFormOptIn}
                    onClose={redirectToHomepage}
                    onGoBack={handleGoBackToSignInOrSignUp}
                    showLinkAndSignIn={handleGoToLinkAndSignIn}
                />
            );
        case FormType.SIGN_IN:
        case FormType.LINK_AND_SIGN_IN:
            let onSignIn = (e: FormEvent) =>
                handleSignIn(e, {
                    emailOrUsername,
                    password,
                    setIsLoading,
                    setFormError,
                    afterSignIn: doRedirect,
                    __secure,
                    contextData,
                });
            if (formState === FormType.LINK_AND_SIGN_IN) {
                onSignIn = (e) =>
                    handleLinkAccountAndSignIn(e, {
                        emailOrUsername,
                        password,
                        setIsLoading,
                        setFormError,
                        afterSignIn: doRedirect,
                        __secure,
                        contextData,
                        linkedPlatforms,
                    });
            }
            return (
                <SignIn
                    emailOrUsername={emailOrUsername}
                    password={password}
                    showPassword={showPassword}
                    isLoading={isLoading}
                    formError={formError}
                    propertyErrors={propertyErrors}
                    onPasswordChange={handlePasswordChange}
                    onToggleShowPassword={handleToggleShowPassword}
                    onSwitchToForgotPasswordForm={handleSwitchToForgotPasswordForm}
                    onSignIn={onSignIn}
                    onSwitchToSignUpForm={handleSwitchToSignUpForm}
                    onEmailOrUsernameChange={handleEmailOrUsernameChange}
                    setPropertyErrors={setPropertyErrors}
                    onClose={redirectToHomepage}
                    onGoBack={handleGoBackToSignInOrSignUp}
                    flowType={flowType}
                />
            );
        case FormType.SIGN_IN_OR_SIGN_UP:
            return (
                <SignInOrSignUp
                    emailOrUsername={emailOrUsername}
                    flowType={flowType}
                    isLoading={isLoading}
                    formError={formError}
                    propertyErrors={propertyErrors}
                    onEmailOrUsernameChange={handleEmailOrUsernameChange}
                    onSignInOrSignUp={(e) =>
                        handleSignInOrSignUp(e, {
                            emailOrUsername,
                            setIsLoading,
                            setEmail,
                            setUsername,
                            setLinkedPlatforms,
                            setFormState: updateFormState,
                            setFormError,
                            setPropertyErrors,
                            propertyErrors,
                            __secure,
                            contextData,
                        })
                    }
                    onSwitchToSignUpForm={handleSwitchToSignUpForm}
                    setPropertyErrors={setPropertyErrors}
                    onClose={redirectToHomepage}
                    password={password}
                    onPasswordChange={handlePasswordChange}
                    onSignIn={(e) =>
                        handleSignIn(e, {
                            emailOrUsername,
                            password,
                            setIsLoading,
                            setFormError,
                            afterSignIn: doRedirect,
                            __secure,
                            contextData,
                        })
                    }
                />
            );
        case FormType.SIGN_UP:
            return (
                <SignUp
                    email={email}
                    password={password}
                    username={username}
                    fullName={fullName}
                    propertyErrors={propertyErrors}
                    onPasswordChange={handlePasswordChange}
                    onUsernameChange={handleUsernameChange}
                    onFullNameChange={handleFullNameChange}
                    onSwitchToSignInForm={handleSwitchToSignInForm}
                    onEmailChange={handleEmailChange}
                    setPropertyErrors={setPropertyErrors}
                    onClose={redirectToHomepage}
                    onGoBack={handleGoBackToSignInOrSignUp}
                    onSuccess={handleShowSignUpSuccess}
                    flowType={flowType}
                />
            );
        case FormType.SIGN_UP_SUCCESS:
            return <SignUpSuccess email={email} onClose={redirectToHomepage} />;
        default:
            // FIXME: PB-7254 - Fix bug where form state changes lead to this default case
            return <>Fallthrough page...</>;
    }
};
