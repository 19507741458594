import { FC, Fragment, memo, ReactElement } from 'react';
import Link from 'next/link';

import { InternalLink } from '@web/atoms/InternalLink';
import { Route } from '@web/routing';

import styles from './LinkList.module.scss';

export enum LinkTarget {
    SELF = '_self',
    BLANK = '_blank',
    PARENT = '_parent',
    TOP = '_top',
}

type BaseLink = { label: string; content?: ReactElement; target?: LinkTarget };
type WithQuery = BaseLink & { url?: never; query: Record<string, string>; route: Route };
type WithUrl = BaseLink & { url: string; query?: never; route?: never };

type LinkItem = WithUrl | WithQuery;

type Links = Array<LinkItem>;

export interface Props {
    links: Links;
    isEmpasized?: boolean;
}

const ComponentList: FC<Props> = ({ links, isEmpasized }) => {
    return (
        <ul className={`${styles.LinkList} ${isEmpasized ? styles['emphasized'] : ''}`}>
            {links.map(({ url, route, label, query, content, target }, index: number) => {
                const isStaticUrl = !!url;
                const isInternalLink = !!(query && route);

                return (
                    <Fragment key={`linkItem-${index}`}>
                        <li className={styles['list-item']}>
                            {isInternalLink ? (
                                <InternalLink route={route} query={query}>
                                    {label}
                                </InternalLink>
                            ) : isStaticUrl ? (
                                <Link rel="nofollow" target={target || LinkTarget.BLANK} href={url}>
                                    {label}
                                </Link>
                            ) : (
                                content
                            )}
                        </li>
                    </Fragment>
                );
            })}
        </ul>
    );
};

ComponentList.displayName = 'LinkList';
export const LinkList = memo(ComponentList);
