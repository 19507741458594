import { FC, FormEvent, useState } from 'react';
import { signOut } from 'supertokens-auth-react/recipe/emailpassword';

import { useTranslation } from '@cms/i18n';
import { SecureService } from '@common/clients/api';
import { ApiService } from '@common/clients/request/ApiService';
import { useContextData } from '@common/useContextData';
import { InternalLink, Route } from '@web/atoms/InternalLink';
import { Footer } from '@web/templates/Signin/atoms/Footer/Footer';
import { LogoContainer } from '@web/templates/Signin/atoms/LogoContainer/LogoContainer';
import { Navigation } from '@web/templates/Signin/atoms/Navigation/Navigation';
import { FormCallbacks } from '@web/templates/Signin/utils';

import { RequestStatus } from '../../types';

import styles from '../ForgotPassword/ForgotPassword.module.scss';

interface Props extends Omit<FormCallbacks, 'onSuccess' | 'onGoBack'> {
    email: string;
}

export const SignUpSuccess: FC<Props> = ({ onClose, email }) => {
    const __secure = useTranslation('secure').t;
    const contextData = useContextData();

    const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.NOT_SENT);

    const handleResendEmail = async (e: FormEvent) => {
        if (requestStatus === RequestStatus.LOADING || requestStatus === RequestStatus.SUCCESS) {
            return;
        }
        setRequestStatus(RequestStatus.LOADING);
        e.preventDefault();

        try {
            await ApiService({
                contextData,
                isClientSide: true,
                service: SecureService,
            }).sendVerificationEmailSecure({ editionId: contextData.context.id });

            setRequestStatus(RequestStatus.SUCCESS);
        } catch (err) {
            setRequestStatus(RequestStatus.ERROR);
        }
    };

    // Sign the user out before closing the modal to allow the user to sign in again without getting stuck in the unverified state.
    const handleClose = async () => {
        await signOut();
        onClose();
    };

    return (
        <div className={styles.ForgotPassword}>
            <div className={styles.wrapper}>
                <Navigation showBackButton={true} onClose={handleClose} />
                <div className={styles.container}>
                    <LogoContainer contextData={contextData} />
                    <h1>{__secure`signin.signUpSuccesHeading`}</h1>
                    <p className={styles.intro}>{__secure('signin.signUpSuccesIntro', { email })}</p>
                </div>
                <form className={styles['sign-up-success']} onSubmit={handleClose}>
                    <div className={styles['submit-button']}>
                        <input className={`button filled`} value={__secure`backToHome`} type="submit" />
                    </div>
                </form>
                <div className={styles['resend-email']}>
                    <p>{__secure('signin.noEmail')}</p>
                    <p>{__secure('signin.tipEmail')}</p>
                    <button className="outlined" onClick={handleResendEmail}>
                        {__secure('signin.resendEmail')}
                    </button>
                </div>
                {requestStatus === RequestStatus.ERROR ? (
                    <div className={styles['form-error']}>
                        <p className={styles.error}>{__secure('signin.formError')}</p>
                    </div>
                ) : null}
                {requestStatus === RequestStatus.SUCCESS ? (
                    <div className={styles['form-success']}>
                        <p>{__secure('signin.emailResent')}</p>
                    </div>
                ) : null}
                <div className={styles['resend-email']}>
                    <p>{__secure('signin.needHelpEmail')}</p>
                    <InternalLink route={Route.Page} query={{ slug: __secure('url:contact') }}>
                        <button className="outlined">{__secure('signin.contact')}</button>
                    </InternalLink>
                </div>
            </div>
            <Footer />
        </div>
    );
};
